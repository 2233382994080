const Submission = () => {
  return (
    <div>
      <h2>Your submission was sent!</h2>
      <h4>
        Return to the Home Page: <a href="/">Home</a>
      </h4>
    </div>
  );
};

export default Submission;
